import * as React from 'react';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { Seo } from '../../components/seo';

const HNBookmarklet = ({ location }) => {
  const pageTitle = 'HackerNews Freelance Thread Filter Bookmarklet';
  return (
    <Layout pageTitle={pageTitle}>
      <Seo index={false} follow={false} pathname={location.pathname} />
      <Article>
        <h1 className="fancy">{pageTitle}</h1>
        <p>
          This bookmarklet works on{' '}
          <a href="https://hn.algolia.com/?dateRange=all&amp;page=0&amp;prefix=false&amp;query=%22Freelancer%3F%20Seeking%20freelancer%3F%22&amp;sort=byDate&amp;type=story">
            the HackerNews monthly “Freelancer? Seeking freelancer?” thread
          </a>
          . It eliminates all the “Seeking work” posts so you’re not having to
          browse through a bunch of other freelancers to get to the gigs.
        </p>
        <p>
          To install the bookmarklet, just drag this link to your bookmarks
          toolbar:
        </p>
        <p>
          <a href="javascript:(function(){document.querySelectorAll('.comtr').forEach(cr=>{if (!cr.textContent.includes('SEEKING FREELANCER')){cr.remove()}})})()">
            Filter HN Freelance Thread
          </a>
        </p>
        <p>
          Click that while viewing the thread, and it will immediately filter
          all the “Seeking work” posts out of the thread!
        </p>
      </Article>
    </Layout>
  );
};
export default HNBookmarklet;
